import React from 'react';
import { useCountdown } from './useCountDown';
import ShowCounter from './ShowCounter';

const CountdownTimer = ({ targetDate }: any) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <div className={'text-sm text-black font-serif'}>grazie mille a tutti di esser stati partecipi del nostro giorno!</div>
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer
