import DateTimeDisplay from "./DateTimeDisplay";

const ShowCounter = ({ days, hours, minutes, seconds }: any) => {
  return (
    <div className="flex flex-col items-center justify-center py-10 bg-white -mx-1">
        <div className="text-4xl text-center grid grid-flow-col gap-2 auto-cols-max">
          <DateTimeDisplay value={days} type={'giorni'} isDanger={days <= 3} />
          <DateTimeDisplay value={hours} type={'ore'} isDanger={false} />
          <DateTimeDisplay value={minutes} type={'minuti'} isDanger={false} />
          <DateTimeDisplay value={seconds} type={'secondi'} isDanger={false} />
      </div>
    </div>
  );
};

export default ShowCounter
