import React from 'react';
import './App.css';
import CountdownTimer from './features/countdown/CountDownTimer';
import LightHouse from './features/lighthouse/LightHouse';
import birds from "./assetts/tettsts.json";
import Lottie from "lottie-react";
import church from "./church.svg"
import cake from "./cake.svg"
import { Tooltip } from 'react-tooltip';
import copy from 'copy-to-clipboard';

const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
</svg>

const App = () => {
    const dateTimeAfterThreeDays = 1719149316000;
    const [isOpen, setIsOpen] = React.useState(false)

    const copyIban = () => {
        copy('IT03S0100573920000000003434')
        //navigator.clipboard.writeText('IT03S0100573920000000003434');
        setIsOpen(true);
        setInterval(() => { setIsOpen(false) }, 1005)
    }

    React.useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 1);
        }, 500)

    }, [])

    return (
        <div className="bg-custom-2 max-w-xl m-auto">
            <div className={'bg-custom grid grid-cols-1 gap-20 pt-10'}>
                <div className='text-center relative flex flex-col gap-3 justify-center items-center'>
                    <Lottie style={{ height: '100%', position: 'absolute', left: '10%', top: '0%' }} animationData={birds} loop={true} />
                    <div className={'flex flex-row justify-center'}>
                        <div className='text-8xl title'>Gianni</div>
                    </div>
                    <div className='text-2xl title text-center'>&</div>
                    <div className={'flex flex-row justify-center'}>
                        <div className='text-8xl title'>Maria</div>
                    </div>
                    <h3 className="invite text-pink brightness-50 text-4xl text-center leading-snug">ci sposiamo!</h3>
                </div>

                <div className={'flex-col flex justify-center items-center'}>
                    <img src="./img/flower_svg.svg" alt="ap"/>
                    <div className={'border border-black w-[250px] -mt-1'} />
                    <div className='text-center mt-3'>
                        <div className="text-black invite uppercase font-bold">
                    <span className="px-3 py-1 text-2xl">
                        23
                    </span>
                            <span className="px-3 py-1 text-2xl">
                        Giugno
                    </span>
                            <span className="px-3 py-1 text-2xl">
                        2024
                    </span>
                        </div>
                    </div>
                </div>


                <div className={'grid grid-cols-1 gap-3'}>
                <h2 className="text-2xl text-center invite text-pink brightness-50">Vi aspettiamo tra</h2>
                <CountdownTimer targetDate={dateTimeAfterThreeDays}/>
                </div>



                <div className='px-4 grid grid-cols-1 sm:grid-cols-2 gap-3'>
                    <div className="sm:max-w-[260px] py-4 px-6 bg-white rounded-xl flex flex-col gap-4">
                        <div className="flex justify-center">
                            <img className="w-20 object-cover" src={church} />
                        </div>
                        <div>
                            <h2 className="text-3xl title">Chiesa</h2>
                            <p className="mt-2 text-light_sc font-bold invite">Sant'Anna</p>
                            <p className="mt-2 text-black text-xs invite">Piazza Sant'Anna, 1, 00048 Nettuno RM</p>
                        </div>
                        <div className="flex justify-end">

                            <a href="https://maps.app.goo.gl/wxW2ExCU3NrUdVUG9" target='_blank' className="text-sm text-light_sc font-bold flex">
                            <div className='text-xs invite mt-1'> vai alla location</div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fill-rule="evenodd" d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" clip-rule="evenodd" />
                                </svg>

                            </a>
                        </div>
                    </div>

                    <div className="sm:max-w-[260px] py-4 px-6 bg-white rounded-xl flex flex-col gap-4">
                        <div className="flex justify-center">
                            <img className="w-20 object-cover " src={cake} />
                        </div>
                        <div>
                            <h2 className="text-3xl title">Ricevimento</h2>
                            <p className="mt-2 text-light_sc font-bold invite">Villa Grant</p>
                            <p className="mt-2 text-black text-xs invite">Via di Pratica, 281, 00128 Roma RM</p>
                        </div>
                        <div className="flex justify-end mt-4">
                            <a href="https://maps.app.goo.gl/pKWx6Wreqt5GNxbYA" target='_blank' className="text-sm text-light_sc font-bold flex">
                                <div className='text-xs invite mt-1'> vai alla location</div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fill-rule="evenodd" d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" clip-rule="evenodd" />
                                </svg>
                            </a>

                        </div>
                    </div>

                </div>



                <div className={'text-black grid grid-cols-1 gap-1 mt-10'}>
                    <div className="invite text-xl text-center">LUNA DI MIELE</div>
                    <div className="invite text-md text-center">per partecipare al nostro sogno</div>
                    <div className='flex flex-row justify-center items-center'>
                        <div className='me-1 invite text-xs'>Iban</div>
                        <div data-tooltip-id="my-tooltip-click"
                        className='p-2 bg-pink opacity-50 font-bold text-xs text-gray-500 sm:text-base rounded-xl flex flex-row justify-center items-center contrast-200'
                        onClick={() => copyIban()}>
                            IT03S0100573920000000003434
                            {copyIcon}
                        </div>
                        <Tooltip
                            id="my-tooltip-click"
                            content="Iban copiato!"
                            isOpen={isOpen}
                        />
                    </div>
                    <div className="invite text-center text-xs"> intestato a <span className='font-bold'>Gianni Toniolo</span></div>

                    <LightHouse />
                </div>
            </div>


        </ div>

    );
}

export default App;
