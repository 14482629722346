import React from 'react';

const DateTimeDisplay = ({ value, type, isDanger }: any) => {
  return (
    <div className="flex flex-col p-2 gap-3 rounded-lg bg-pink text-black">
      <div className="font-serif leading-none">
        <div className={isDanger ? 'text-yellow-400' : 'text-black'}>{value}</div>
      </div>
      <div className='invite uppercase text-sm leading-none'>{type} </div>
    </div>
  );
};

export default DateTimeDisplay;
